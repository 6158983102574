import { createContext,useContext } from "react";
import {
    createUserWithEmailAndPassword
    ,signInWithEmailAndPassword,
    signOut,
    signInWithPopup,
    sendPasswordResetEmail,
    GoogleAuthProvider
} from 'firebase/auth';
import { auth } from '../firebase/firebase';

const userContext=createContext();
export function UserContextProvider({ children }) {


    function signup(email,pass) { return createUserWithEmailAndPassword(auth,email,pass); }

    function login(email,pass) { return signInWithEmailAndPassword(auth,email,pass) }
    function forgetPassword(email) { return sendPasswordResetEmail(auth,email) }
    function logOut() { return signOut(auth); }

    function signupWithGoogle() {
        const googleAuthProvider=new GoogleAuthProvider();
        return signInWithPopup(auth,googleAuthProvider)
    }

    return (
        <userContext.Provider value={{ signup,login,logOut,signupWithGoogle,forgetPassword }}>
            {children}
        </userContext.Provider>
    )
}
export function useUserContext() {
    return useContext(userContext);
}




