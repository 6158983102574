import { Alert,AlertTitle,Button,Grid,Paper,Typography,Link } from "@mui/material";
import React,{ useState } from "react";
import verifierImg from "./verifier_screen.svg";
import { useLocation } from "react-router-dom";
import { useUserContext } from "../context/userAuthcontext";
const paperStyle={
  padding: "0 20px 30px",
  height: "100vh",
  width: "360px",
  display: "grid",
  placeItems: "center",
  margin: "0 auto",
};
const Verifier=() => {
  let { forgetPassword }=useUserContext();
  const search=useLocation().search;
  let oldemail=new URLSearchParams(search).get("email");
  let email=oldemail.replace(/(\w{3})[\w.-]+@([\w.]+\w)/,"$1***@$2");
  const [error,setError]=useState(0);
  async function forgetPass(e) {
    e.preventDefault();
    try {

      await forgetPassword(oldemail);
    } catch (err) {
      setError(err.message)
    }
  }
  return (
    <Grid>
      {error? <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {error}
      </Alert>:""}
      <Paper elevation={0} style={paperStyle} >
        <Grid align="center">
          <img src={verifierImg} alt="welcome user" />
          <h1 style={{ margin: "2px" }}>Verification mail sent </h1>
          <Typography my={2}>
            We've sent mail to <span style={{ fontWeight: "700" }}>{email}</span> Please check your email and click on the link to verify you email address.
          </Typography>
          <Typography mt={6} fontSize="1.2rem" fontWeight="600">Haven't got? <Button color="secondary" onClick={forgetPass} >Resend</Button> </Typography>

        </Grid>
      </Paper>
    </Grid>
  );
};

export default Verifier;
