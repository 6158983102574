import { Alert,AlertTitle,Button,Grid,Paper,Typography,Link } from "@mui/material";
import React,{ useState,useRef } from "react";
import ForgetPasswordImg from "./ForgetPassword_screen-.svg";
import "./form.css"
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../context/userAuthcontext";

const paperStyle={
  padding: "20px",
  height: "100vh",
  width: "360px",
  display: "grid",
  placeItems: "center",
  margin: "0 auto ",
};
const ForgetPassword=() => {
  const navigate=useNavigate();
  let { forgetPassword }=useUserContext();
  let emailRef=useRef();
  const [error,setError]=useState(0);
  const search=useLocation().search;
  const client_id=new URLSearchParams(search).get("client_id");
  const response_type=new URLSearchParams(search).get("response_type");
  const redirect_uri=new URLSearchParams(search).get("redirect_uri");
  const state=new URLSearchParams(search).get("state");
  async function forgetPass(e) {
    e.preventDefault();
    try {
      await forgetPassword(emailRef.current.value);
      navigate(`/verify?email=${emailRef.current.value}`)
    } catch (err) {
      setError(err.message)
    }
  }
  return (
    <Grid>
      {error? <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {error}
      </Alert>:""}
      <Paper elevation={0} style={paperStyle} >
        <Grid>
          <h1 >Forget Password </h1>
          <Typography color="#9CA3AF" fontWeight="600">
            Enter your registered email below.
          </Typography>
          <img src={ForgetPasswordImg} alt="welcome user" style={{ margin: "2px 16px" }} />

          <form className="form">
            <label>Email Address </label>
            <input
              type="email"
              ref={emailRef}
              placeholder="name@gmail.com"
              required
            />
            <Typography mt={-2} color="#9CA3AF">Remember the password? <Link href={`/login?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}`} color="secondary" underline="hover">Sign In</Link></Typography>

            <Button
              variant="contained"
              color="secondary"
              type="submit"
              sx={{
                width: "275px",
                background: "#684AFF",
                height: "50px",
                borderRadius: "10px",
                margin: "40px auto 10px",
                textTransform: "none"
              }}
              onClick={forgetPass}
            >
              <Typography color="#fff" fontWeight="500" fontSize="1.2rem">
                Submit
              </Typography>
            </Button>
          </form>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ForgetPassword;
