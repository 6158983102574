
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth';

   //fire
// const firebaseConfig = {
//   apiKey: "AIzaSyBIv1EruozqDbYigxg1tM28EbHA0XJV8qw",
//   authDomain: "livsmartnew.firebaseapp.com",
//   databaseURL: "https://livsmartnew-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "livsmartnew",
//   storageBucket: "livsmartnew.appspot.com",
//   messagingSenderId: "282125427239",
//   appId: "1:282125427239:web:f4bf3aa6d77727ddcd2402",
//   measurementId: "G-V57374DL31"
// };
//testAssisstant
// const firebaseConfig={
//   apiKey: "AIzaSyBoZMjHaIzL1iMI1cKVJLKqPBSGxm08xyE",
//   authDomain: "newtest-92512.firebaseapp.com",
//   databaseURL: "https://newtest-92512-default-rtdb.firebaseio.com",
//   projectId: "newtest-92512",
//   storageBucket: "newtest-92512.appspot.com",
//   messagingSenderId: "147849684844",
//   appId: "1:147849684844:web:98525fcbd3967fbeebf549"
// }
//livsmart 
const firebaseConfig={
  apiKey: "AIzaSyBFpEcdsHSeSDsIpZyltGEbV_9_lQzLnHA",
  authDomain: "oldtown-21.firebaseapp.com",
  databaseURL: "https://oldtown-21-default-rtdb.firebaseio.com",
  projectId: "oldtown-21",
  storageBucket: "oldtown-21.appspot.com",
  messagingSenderId: "191355460875",
  appId: "1:191355460875:web:e7438397dbe7bf79be160e",
  measurementId: "G-VX2B1VXEJG"
}
//dev
// const firebaseConfig = {
//   apiKey: "AIzaSyAS5lbCoyeJDL4jvDtUzobvmttqNtEnoQk",
//   authDomain: "livsmart-dev.firebaseapp.com",
//   projectId: "livsmart-dev",
//   storageBucket: "livsmart-dev.appspot.com",
//   messagingSenderId: "208040758833",
//   appId: "1:208040758833:web:d4b0ebf249a7644cc8cf10",
//   measurementId: "G-FSEKJWBYGN"
// };
const app = initializeApp(firebaseConfig);
export const auth=getAuth(app);
export default app;



