import { Routes,Route } from 'react-router-dom';
import Login from "./pages/Login"
import ForgetPassword from "./pages/ForgetPassword"
import { UserContextProvider } from './context/userAuthcontext';
import Verifier from './pages/Verifier'
import GoogleAuth from './pages/GoogleAuth';

function App() {
  return (
    <UserContextProvider>
      <Routes>

        <Route path="/" element={<Login></Login>} />
        <Route path="/google-auth" element={<GoogleAuth></GoogleAuth>} />
        <Route path="/login" element={<Login></Login>} />
        <Route path="/forgetPassword" element={<ForgetPassword></ForgetPassword>} />
        <Route path="/verify" element={<Verifier></Verifier>} />

      </Routes>
    </UserContextProvider>
  );
}

export default App;
