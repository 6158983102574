import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import isAssistantRequest from "../util/credCheck";
import url from "../util/endpoints";

const GoogleAuth = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const client_id = new URLSearchParams(search).get("client_id");
  const response_type = new URLSearchParams(search).get("response_type");
  const redirect_uri = new URLSearchParams(search).get("redirect_uri");
  const state = new URLSearchParams(search).get("state");
  const accessToken = new URLSearchParams(search).get("accessToken");
  let message =
    redirect_uri === "https://oauth-redirect.googleusercontent.com/r/oldtown-21"
      ? "Google"
      : "Amazon";

  function accountLinking() {
    if (isAssistantRequest(response_type, client_id, redirect_uri, state)) {
      let redirec = `${url.prod_api_auth_url}?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}&accessToken=${accessToken}`;
      window.location.href = redirec;
    }
  }
  useEffect(() => {
    if (!isAssistantRequest(response_type, client_id, redirect_uri, state))
      navigate("/login");
  }, []);

  return (
    <div
      style={{
        margin: "auto",
        marginTop: "200px",
        width: "fit-content",
        textAlign: "center",
        padding: "3%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src="livlogo.png" width="100" height="100"/>
        {/* <img
          src="link.png"
          style={{ width: "40px", height: "30px", margin: "0 0 0 15px" }}
        />
        <img
          src={
            redirect_uri ===
            "https://oauth-redirect.googleusercontent.com/r/oldtown-21"
              ? "Google_Assistant_logo.svg.png"
              : "alexa.png"
          }
          width={
            redirect_uri ===
            "https://oauth-redirect.googleusercontent.com/r/oldtown-21"
              ? "100px"
              : "150px"
          }
        /> */}
      </div>
      <p style={{ fontFamily: "sans-serif" }}>
        By signing in, you are authorizing {message} to control your devices
      </p>
      <button
        style={{
          padding: "15px",
          outline: "none",
          border: "none",
          borderRadius: "10px",
          background: "#600093",
          color: "white",
        }}
        onClick={accountLinking}
      >
        Link with {message}
      </button>
    </div>
  );
};

export default GoogleAuth;
