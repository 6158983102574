import React, { useState, useRef } from "react";
import { useUserContext } from "../context/userAuthcontext";
import { useLocation } from "react-router-dom";
import {
  AlertTitle,
  Alert,
  Button,
  Grid,
  Paper,
  Typography,
  Link,
} from "@mui/material";
import "../util/credCheck";
import "./form.css";
import url from "../util/endpoints";
import { FaGoogle } from "react-icons/fa";

import { deleteUser } from "firebase/auth";

import isAssistantRequest from "../util/credCheck";

const paperStyle = {
  padding: "20px",
  height: "100vh",
  width: "360px",
  display: "grid",
  placeItems: "center",
  margin: "0 auto ",
};

const Login = (props) => {
  let { login, signupWithGoogle } = useUserContext();
  let emailRef = useRef();
  let passRef = useRef();
  const search = useLocation().search;
  const client_id = new URLSearchParams(search).get("client_id");
  const response_type = new URLSearchParams(search).get("response_type");
  const redirect_uri = new URLSearchParams(search).get("redirect_uri");
  const state = new URLSearchParams(search).get("state");

  const [error, setError] = useState(0);

  // console.log(process.env);

  async function logIn(e) {
    setError(0);
    e.preventDefault();
    try {
      let { user } = await login(emailRef.current.value, passRef.current.value);

      if (isAssistantRequest(response_type, client_id, redirect_uri, state))
        window.location.href = `https://auth.thelivsmart.com/google-auth?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}&accessToken=${user.accessToken}`;
    } catch (err) {
      setError(err.message);
    }
  }

  async function signUpWithGoogle(e) {
    e.preventDefault();
    try {
      setError(0);
      let { user } = await signupWithGoogle();

      if (isAssistantRequest(response_type, client_id, redirect_uri, state)) {
        let redirec = `https://auth.thelivsmart.com/google-auth?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}&accessToken=${user.accessToken}`;
        const da = await fetch(url.prod_api_profile_url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${user.accessToken}`,
          },
        });
        const data = await da.json();
        if (data?.error?.code === "USER_PROFILE_NOT_FOUND") {
          await deleteUser(user);
          throw new Error();
        }

        window.location.href = redirec;
      }
    } catch (err) {
      setError(err.message);
    }
  }

  return (
    <Grid>
      {error ? (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      ) : (
        ""
      )}
      <Paper elevation={0} style={paperStyle}>
        <Grid>
          <Grid align="center">
            <img src="sitelogo.png" width="250px" />
            <br />
            <br />
          </Grid>
          <form className="form">
            <label>Email Address </label>
            <input
              type="email"
              ref={emailRef}
              placeholder="name@gmail.com"
              required
            />
            <label>Password </label>
            <input
              type="password"
              placeholder="Password"
              ref={passRef}
              required
            />
            <Link
              href={`/forgetPassword?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}`}
              color="secondary"
              underline="hover"
              className="right-link"
            >
              Forget Password?{" "}
            </Link>

            <Grid align="center">
              <Button
                variant="contained"
                type="submit"
                sx={{
                  width: "265px",
                  height: "45px",
                  borderRadius: "10px",
                  margin: "45px auto 15px",
                  textTransform: "none",
                  color: "#fff",
                  background: "#600093",
                }}
                onClick={logIn}
              >
                <Typography fontWeight="500" fontSize="1rem">
                  Login
                </Typography>
              </Button>
              <Button
                sx={{
                  width: "265px",
                  height: "45px",
                  borderRadius: "10px",
                  background: "#E4DFFF",
                  textTransform: "none",
                  color: "#684AFF",
                }}
                startIcon={<FaGoogle />}
                onClick={signUpWithGoogle}
              >
                <Typography p={2} fontWeight="500" fontSize="1rem">
                  Sign up with google
                </Typography>
              </Button>
            </Grid>
          </form>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Login;
