function isAssistantRequest(response_type,client_id ,redirect_uri,state) {
    //verifying responseType
    if (response_type !== "code") return false;

    //verifying clientID
    if (
      client_id !==
      "191355460875-8d5tess9rbpub6itq6qmnes2meiuk1eh.apps.googleusercontent.com"
    )
      return false;

    //verifying redirectURI
    if (
      redirect_uri !== "https://ifttt.com/channels/livsmart/authorize" &&
      redirect_uri !==
        "https://layla.amazon.com/api/skill/link/M34TWOIXIIOWK2" &&
      redirect_uri !==
        "https://oauth-redirect.googleusercontent.com/r/oldtown-21"
    )
      return false;

    // checking if state is present or not
    if (!state) return false;
    return true;
  }

  export default isAssistantRequest